import { Http } from '@/helpers/http-helper'

const actions = {
  createFeedback ({ commit }, data) {
    return Http.post('feedbacks/create', data)
      .then(response => {
        return response.data
      }).catch(err => {
        throw err
      })
  },
  storeSubscriptionEmail ({ commit }, data) {
    return Http.post('/newsletter', data)
      .then(response => {
        return response.data
      }).catch(err => {
        throw err
      })
  }
}

export default {
  namespaced: true,
  actions: actions
}

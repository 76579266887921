import { Http } from '@/helpers/http-helper'

const state = {
  feed: null,
  baseFilePath: null,
  outfitItemRatings: null,
  time: null
}

const getters = {
  getFeed: state => state.feed,
  getBaseFilePath: state => state.baseFilePath,
  getOutfitItemRatings: state => state.getOutfitItemRatings,
  getTime: state => state.time
}

const mutations = {
  setFeed (state, data) {
    state.feed = data
  },
  setBaseFilePath (state, data) {
    state.baseFilePath = data
  },
  setOutfitItemRatings (state, data) {
    state.outfitItemRatings = data
  },
  setTime (state, data) {
    state.time = data
  }
}

const actions = {
  getDemoFeed ({ commit, state }, id) {
    const data = {
      'user_id': id
    }
    return Http.post('feed/demo', data).then(resp => {
      commit('setBaseFilePath', resp.data.baseFilePath)
      commit('setOutfitItemRatings', resp.data.outfitItemRatings)
      commit('setTime', resp.data.time)
      commit('setFeed', Array.isArray(resp.data.outfit) === false ? resp.data.outfit : null)
      return Array.isArray(resp.data) === false ? resp.data : false
    }).catch(error => {
      commit('setFeed', null)
      return error.response.data
    })
  },
  rateFeed ({ commit, state }, data) {
    return Http.post('feed/demo/add-rating', data).then(resp => {
      return resp.data
    }).catch(err => Promise.reject(err))
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}

import axios from 'axios'
import router from '../router'
import { store } from '../store'

/**
 *  Http used when no authentication needed (login, register, etc)
 *  AuthHttp used after user is logged in, almost all data
 */

export const Http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

Http.interceptors.request.use(function (request) {
  request.headers.Authorization = 'Bearer ' + store.state.auth.token.token
  return request
})

Http.interceptors.response.use(response => {
  return response
}, (error) => {
  if (error === undefined) {
    return Promise.reject(error)
  }
  if (error.response.status === 401) {
    localStorage.clear()
  }
  if (error.response.status === 503) {
    localStorage.setItem('countdownDate', error.response.data)
    router.push({ name: 'Info' })
  }
  if ((error.response.status === 401 || error.response.status === 403) && router.currentRoute.path !== '/login/') {
    router.push({ name: 'Login' })
  }

  return Promise.reject(error)
})

export const AuthHttp = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

<template>
  <div id="app">
    <router-view/>
    <cookie v-if="isCookieDailogShow" @closeCookieDialogBox="closeCookiePopup"></cookie>
  </div>
</template>

<script>
import Cookie from './components/modals/Cookie'
export default {
  data: function () {
    return {
      isCookieDailogShow: true
    }
  },
  name: 'App',
  components: {
    Cookie: Cookie
  },
  methods: {
    closeCookiePopup (data) {
      switch (data) {
        case 'hide':
          this.isCookieDailogShow = false
          break
        case 'reject':
          this.isCookieDailogShow = false
          break
        case 'accept':
          this.isCookieDailogShow = false
          break
      }
    }
  },
  mounted () {
    let cookieConsent = this.$cookie.get('cookieConsent')
    if (cookieConsent === 'false' || cookieConsent === 'true') {
      this.isCookieDailogShow = false
    }
  }
}
</script>

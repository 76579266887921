import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store'
/**
 * WIO Base view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const BaseView = () => import('@/views/BaseView')

/**
 * WIO login view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const LoginIndexView = () => import('@/views/auth/login/Index')
const LoginView = () => import('@/views/auth/login/Login')

/**
 * WIO signup view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const SignUpIndexView = () => import('@/views/auth/sign-up/Index')
// const SignUpView = () => import('@/views/auth/sign-up/SignUp')
const SignUpEmailView = () => import('@/views/auth/sign-up/SignUpEmail')
const SignUpProfilePhotoView = () => import('@/views/auth/sign-up/SignUpProfilePhoto')
const SignUpProfileDataView = () => import('@/views/auth/sign-up/SignUpProfileData')
const FacebookConnectView = () => import('@/views/auth/sign-up/FbConnect')

/**
 * WIO Password options views
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const PasswordIndexView = () => import('@/views/auth/password/Index')
const ResetPasswordView = () => import('@/views/auth/password/Reset')
const ForgotPasswordView = () => import('@/views/auth/password/Forgot')

/**
 * WIO Style Quiz view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const StyleQuizView = () => import('@/views/auth/quiz/StyleQuiz')

/**
 * WIO Home view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const HomeIndexView = () => import('@/views/home/Index')
const HomeView = () => import('@/views/home/Home')

/**
 * WIO Profile view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const ProfileView = () => import('@/views/profile/Profile')

/**
 * WIO Notifications view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const NotificationsView = () => import('@/views/notifications/Notifications')
const NotificationSetupView = () => import('@/views/notifications/NotificationSetup')

/**
 * WIO Settings views
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const SettingsIndexView = () => import('@/views/settings/Index')
const SettingsView = () => import('@/views/settings/Settings')
const SettingsAccountView = () => import('@/views/settings/SettingsAccount')
const SettingsManageView = () => import('@/views/settings/SettingsManage')

/**
 * WIO Email Veify view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */

const EmailIndexView = () => import('@/views/auth/email-verify/Index')
const EmailView = () => import('@/views/auth/email-verify/Email')

/**
 * WIO info view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const WeekendInfo = () => import('@/views/auth/info/weekend-info')
const DemoFeed = () => import('@/views/pages/Demo')

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/fb',
    component: FacebookConnectView,
    props: route => ({
      code: route.query.code
    })
  },
  {
    path: '/login',
    component: LoginIndexView,
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginView
      },
      {
        path: 're-auth',
        name: 'ReAuthenticate',
        component: () => import('@/views/auth/login/ReAuthenticate')
      }
    ]
  },
  {
    path: '/info',
    name: 'Info',
    component: WeekendInfo
  },
  {
    path: '/app',
    name: 'DemoFeed',
    component: DemoFeed
  },
  {
    path: '/signup',
    component: SignUpIndexView,
    redirect: '/signup/email',
    children: [
      // {
      //   path: '',
      //   name: 'SignUp',
      //   component: SignUpView
      // },
      {
        path: 'email',
        name: 'SignUpEmail',
        component: SignUpEmailView
      },
      {
        path: 'avatar',
        name: 'UpdateAvatar',
        component: SignUpProfilePhotoView,
        beforeEnter: (to, from, next) => {
          if (store.state.auth.token.token) {
            if (store.state.auth.user?.setup_step === 1) next()
            else next({ name: 'Home' })
          } else next('/login')
        }
      },
      {
        path: 'profile',
        name: 'UpdateProfile',
        component: SignUpProfileDataView,
        beforeEnter: (to, from, next) => {
          if (store.state.auth.token.token) {
            if (store.state.auth.user?.setup_step === 2) next()
            else next({ name: 'Home' })
          } else next('/login')
        }
      },
      {
        path: 'quiz',
        name: 'StyleQuiz',
        component: StyleQuizView,
        beforeEnter: (to, from, next) => {
          if (store.state.auth.token.token) {
            if (store.state.auth.user?.setup_step === 3) next()
            else next({ name: 'Home' })
          } else next('/login')
        }
      }
    ]
  },
  {
    path: '/notifications-setup',
    component: NotificationSetupView
  },
  {
    path: '/password',
    redirect: '/password/forgot',
    component: PasswordIndexView,
    children: [
      {
        path: 'forgot',
        name: 'ForgotPassword',
        component: ForgotPasswordView
      },
      {
        path: 'reset/:token',
        name: 'ResetPassword',
        props: route => ({
          email: route.query.email
        }),
        component: ResetPasswordView
      }
    ]
  },
  {
    path: '/email',
    redirect: '/email/verify',
    component: EmailIndexView,
    children: [
      {
        path: 'verify',
        name: 'EmailVerify',
        props: route => ({
          hash: route.query.hash,
          signature: route.query.signature
        }),
        component: EmailView
      }
    ]
  },
  {
    path: process.env.VUE_APP_PUBLIC_PATH,
    component: BaseView,
    beforeEnter: (to, from, next) => {
      if (to.name === 'Page') {
        next()
      } else if (!store.state.auth.token.token) {
        next({ name: 'Login' })
      } else {
        store.dispatch('auth/searchBase')
        switch (store.state.auth.user.setup_step) {
          case 1:
            next({ name: 'UpdateAvatar' })
            break
          case 2:
            next({ name: 'UpdateProfile' })
            break
          case 3:
            next({ name: 'StyleQuiz' })
            break
          default:
            next()
            break
        }
      }
    },
    children: [
      {
        path: '',
        component: HomeIndexView,
        children: [
          {
            path: '/',
            name: 'Home',
            component: HomeView
          }
        ]
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ProfileView
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: NotificationsView,
        meta: {
          noHeader: true
        }
      },
      {
        path: 'settings',
        component: SettingsIndexView,
        children: [
          {
            path: '',
            name: 'Settings',
            component: SettingsView,
            meta: {
              noHeader: true
            }
          },
          {
            path: 'manage/:slug?',
            name: 'SettingsManage',
            component: SettingsManageView,
            meta: {
              noHeader: true
            }
          },
          {
            path: 'account/:slug?',
            name: 'SettingsAccount',
            component: SettingsAccountView,
            meta: {
              noHeader: true
            }
          }
        ]
      },
      {
        path: 'general',
        name: 'General',
        component: () => import('@/views/pages/GeneralEl')
      },
      {
        path: ':slug',
        name: 'Page',
        component: () => import('@/views/pages/PageView'),
        meta: {
          noHeader: true
        }
      },
      {
        path: '404',
        component: () => import('@/views/pages/NotFound')
      },
      {
        path: '*',
        redirect: '/404'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})
export default router

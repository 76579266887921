import Vue from 'vue'
import Vuex from 'vuex'

import persistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import translations from './modules/translations'
import feedback from './modules/feedback'
import demoOutfit from './modules/demoOutfit'

// import authStore from './modules/auth'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    translations,
    feedback,
    demoOutfit
  },
  plugins: [persistedState()]
})

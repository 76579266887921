<template>
    <div id="cookiePopup" ref="cookiePopup" class="cookie-show">
        <img class="cookie-close" @click="close" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVR4nN2UW04CUQyGCQReRN8gsgV0D+IGDBjDFshMVNBFEOIyvK5HMIKoizD4oC9+pqaTFFLPDF5eaHKSmfZv/56etrncSgtQAtrANTABXvXI95XaSj8Nvg88ky5PQGuZwHngzAS4BY6BOrCmZwvoAkODG4hvFoIk+BvQCTlpMpFiv0iylCUJvrPErRuGpBl60KTmHaOPgKqDr4rN/Mfq+wgUPYK2qXl+wWlkSTS46ERi1RWM7sAjuFHjkdFVzEOOgZqj2zT4nuovPYIHNdadUozMTez3XOm0u0QmHsFMjWXHVlloybnMDW5d7bO/IKg5uA21v/xXibZDJZLdItL9JvOxlMV7eIM/Vf15qE2Hv2jTu1CblnRxsTBAUcZBO1TfqTtoCmopSMa+4YJ8v13gHfgA9tLAA0MSy9UD2IJmLsFF+lmyyRsStNY9HaKyHumWE1NzybyfaV0boqYurjSZppYlQFKUjpDdoi0pwyjnHrhQm/+gKyOftQCKEvgzv6sAAAAASUVORK5CYII=">
        <div class="content">
            <p>
                Our app uses cookies (vegan and gluten free, obvs). Before continuing to use our website, you agree &
            accept of our
            <router-link class="text-underline" :to="{ name: 'Page', params: { slug: 'terms' }}">Cookie Policy & Privacy.</router-link>
            </p>
            <center class="mt-2">
                <button id="acceptCookie" @click="acceptCookie" class="btn btn-primary">Accept</button>
                <button id="rejectCookie" @click="rejectCookie" class="ml-2 btn btn-danger">Reject</button>
            </center>
        </div>
    </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'
export default {
  methods: {
    close () {
      this.$emit('closeCookieDialogBox', 'hide')
    },
    acceptCookie () {
      this.$cookie.set('cookieConsent', true)
      bootstrap().then((gtag) => {
        this.$emit('closeCookieDialogBox', 'accept')
      })
    },
    rejectCookie () {
      this.$cookie.set('cookieConsent', false)
      this.$emit('closeCookieDialogBox', 'reject')
    }
  },
  mounted () {

  }
}
</script>
